import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const data = useStaticQuery(graphql`
    query headerBioQuery {
      site {
        siteMetadata {
          author {
            name
            summary
          }
        }
      }
    }
  `);

  let author = data.site.siteMetadata.author;

  return <>
    <header className="global-header">
      <div className="wrap">
        <h1 className="main-heading">
          <Link className="main-heading__name" to="/">
            {author.name}
          </Link>
        </h1>
        <span className="main-heading__summary">
          {author.summary}
        </span>
      </div>
    </header>
    <div className="wrap" data-is-root-path={isRootPath}>
      <main>{children}</main>
      <footer>© {new Date().getFullYear()}, Jeff Magill</footer>
    </div>
  </>
}

export default Layout
